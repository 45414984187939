import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;

}
export const ROUTES: RouteInfo[] = [
    { path: '', title: 'Home',  icon: 'ni-shop text-blue', class: '' },
    { path: '/competitors/1', title: 'Competitors',  icon: 'ni-camera-compact text-orange', class: '' },
    { path: '/how-it-works', title: 'How It Works',  icon: 'ni-compass-04 text-primary', class: '' },
    { path: '/faq', title: 'FAQ',  icon: 'ni-chat-round text-blue', class: '' },
    { path: '/current-charity', title: 'Charity Beneficiary',  icon: 'ni-favourite-28 text-red', class: '' },
    { path: '/terms-and-conditions', title: 'Terms & Conditions',  icon: 'ni-collection text-primary', class: '' }


  ];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
   });
  }
}
